import React from "react";
import "./PrivacyPolicy.scss";

const PrivacyPolicy = () => {
    return (
        <div className="privacy-policy">
            <h1>Privacy Policy for Fitoja</h1>
            <p>
                Fitoja ("we," "our," or "us") is committed to protecting your privacy. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you use our mobile application ("App") available on the Google Play Store.
            </p>
            <p>
                By using the Fitoja App, you agree to the collection and use of information in accordance with this policy.
            </p>

            <h2>Information We Collect</h2>
            <h3>Personal Information</h3>
            <p>
                To provide personalized diet plans and services, we may collect the following personal information:
            </p>
            <ul>
                <li>Gender</li>
                <li>Age</li>
                <li>Health condition</li>
                <li>Weight</li>
                <li>Lifestyle choices</li>
            </ul>

            <h3>Health Tracking Information</h3>
            <p>
                We may collect data related to your health and wellness, including:
            </p>
            <ul>
                <li>Body Mass Index (BMI)</li>
                <li>Progress reports</li>
                <li>Calorie tracking data</li>
            </ul>

            <h3>Transaction Information</h3>
            <p>
                If you make purchases from our in-app store, we collect:
            </p>
            <ul>
                <li>Payment information (processed through a secure third-party provider)</li>
                <li>Purchase history</li>
            </ul>

            <h3>Usage Data</h3>
            <p>
                We may collect information about how you access and use the App, including:
            </p>
            <ul>
                <li>Device information (e.g., IP address, device type)</li>
                <li>Log data (e.g., time and date of access, pages viewed)</li>
                <li>Analytics data (e.g., interactions with features)</li>
            </ul>

            <h2>How We Use Your Information</h2>
            <p>We use the information we collect for the following purposes:</p>
            <ul>
                <li>To provide and maintain our services</li>
                <li>To create personalized diet plans based on your individual profile</li>
                <li>To track your health progress and provide recommendations</li>
                <li>To manage your account and provide customer support</li>
                <li>To process transactions made in our in-house store</li>
                <li>To send you updates, newsletters, and marketing communications (with your consent)</li>
                <li>To improve our App and services through data analysis</li>
            </ul>

            <h2>Sharing Your Information</h2>
            <p>We do not sell, trade, or otherwise transfer your personal information to third parties without your consent, except in the following situations:</p>
            <ul>
                <li>
                    <strong>Service Providers:</strong> We may share your information with trusted third-party service providers who assist us in operating our App, conducting our business, or servicing you, as long as those parties agree to keep this information confidential.
                </li>
                <li>
                    <strong>Legal Compliance:</strong> We may disclose your information if required to do so by law or in response to valid requests by public authorities.
                </li>
            </ul>

            <h2>Data Security</h2>
            <p>
                We take reasonable measures to protect the personal information we collect from loss, theft, and misuse, as well as unauthorized access, disclosure, alteration, and destruction. However, no method of transmission over the internet or method of electronic storage is 100% secure, and we cannot guarantee its absolute security.
            </p>

            <h2>Your Rights</h2>
            <p>You have the right to:</p>
            <ul>
                <li>Access the personal information we hold about you</li>
                <li>Request correction of your personal information</li>
                <li>Request deletion of your personal information</li>
                <li>Opt-out of receiving marketing communications</li>
            </ul>
            <p>To exercise any of these rights, please contact us using the contact information below.</p>

            <h2>Third-Party Links</h2>
            <p>
                Our App may contain links to third-party websites or services that are not operated by us. We are not responsible for the content, privacy policies, or practices of these third-party sites. We encourage you to review the privacy policies of any third-party sites you visit.
            </p>

            <h2>Changes to This Privacy Policy</h2>
            <p>
                We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the effective date. You are advised to review this Privacy Policy periodically for any changes.
            </p>

            <h2>Contact Us</h2>
            <p>If you have any questions about this Privacy Policy, please contact us at:</p>
            <p>
                Email: <a href="mailto:info@fitoja.in">info@fitoja.in</a>
            </p>
            <p>By using the Fitoja App, you consent to this Privacy Policy. Thank you for trusting us with your health and wellness journey!</p>
        </div>
    );
};

export default PrivacyPolicy;
