import React from "react";
import "./CustomerSupport.scss";

const CustomerSupport = () => {
    return (
        <div className="customer-support">
            <h1>Customer Support for Fitoja</h1>
            <p>
                At Fitoja, we are committed to providing you with the best possible support. If you have any questions, concerns, or feedback regarding our app or services, please don't hesitate to reach out to us. Here’s how you can get in touch:
            </p>

            <h2>Contact Us</h2>
            <h3>Email Support</h3>
            <p>
                For general inquiries, technical issues, or support related to your account, please contact us at:
            </p>
            <p><strong>Email:</strong> <a href="mailto:info@fitoja.in">info@fitoja.in</a></p>

            <h3>In-App Support</h3>
            <p>
                You can also reach out to us directly through the app:
            </p>
            <ol>
                <li>Open the Fitoja app.</li>
                <li>Navigate to the "Help" or "Support" section.</li>
                <li>Submit your inquiry, and our support team will get back to you promptly.</li>
            </ol>

            <h2>FAQs</h2>
            <p>
                Before reaching out, you may want to check our Frequently Asked Questions (FAQs) section within the app. It covers common issues and inquiries, which may provide you with immediate assistance.
            </p>

            <h2>Support Hours</h2>
            <p>Our customer support team is available:</p>
            <ul>
                <li><strong>Monday to Friday:</strong> 9:00 AM - 6:00 PM (IST)</li>
                <li><strong>Saturday & Sunday:</strong> Closed</li>
            </ul>

            <h2>Feedback</h2>
            <p>
                We value your feedback! If you have suggestions or ideas on how we can improve your experience with Fitoja, please let us know.
            </p>

            <p><strong>Thank you for choosing Fitoja! We look forward to assisting you on your health and wellness journey.</strong></p>
        </div>
    );
};

export default CustomerSupport;
