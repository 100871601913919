import React from "react";
import "./AboutUs.scss";

const AboutUs = () => {
    return (
        <div className="about-us">
            <h1>Fitoja App: Your Personalized Health and Diet Companion</h1>
            <p>
                Fitoja is an innovative health and diet app designed to empower individuals on their journey toward better health through personalized diet plans and holistic wellness solutions. With a focus on individualized approaches, Fitoja provides tailored recommendations based on users’ unique profiles, including gender, age, health conditions, weight, and lifestyle choices.
            </p>

            <h2>Key Features of Fitoja:</h2>
            <ul>
                <li>
                    <strong>Personalized Diet Plans:</strong> Fitoja creates customized diet plans that cater to individual nutritional needs. By considering various factors, the app ensures that users receive dietary guidance that aligns with their health goals.
                </li>
                <li>
                    <strong>BMI and Progress Tracking:</strong> Users can easily monitor their Body Mass Index (BMI) and track their weight loss progress. This feature allows for real-time adjustments to diet plans, promoting accountability and motivation.
                </li>
                <li>
                    <strong>Calorie Tracking:</strong> The app includes a comprehensive calorie tracker that helps users stay within their daily caloric limits while enjoying a balanced diet. This tool is essential for effective weight management.
                </li>
                <li>
                    <strong>Ayurvedic Store:</strong> Fitoja features an in-house store where users can purchase Ayurvedic herbs and spices known for their health benefits. These natural products support weight loss and overall well-being.
                </li>
                <li>
                    <strong>Engaging Health Blogs:</strong> The app offers a wealth of informative blogs related to health, nutrition, and weight loss. Users can stay updated on the latest trends and research in wellness.
                </li>
                <li>
                    <strong>User-Friendly Interface:</strong> Designed with simplicity in mind, Fitoja's interface is intuitive and easy to navigate, making it accessible for users of all ages.
                </li>
            </ul>

            <h2>Why Choose Fitoja?</h2>
            <p>
                Fitoja stands out as a holistic health solution that not only emphasizes weight loss but also encourages overall wellness. By combining personalized diet plans with traditional Ayurvedic practices, the app provides users with the tools they need to lead healthier lives.
            </p>
            <p>
                With a focus on community support and education, Fitoja empowers users to take charge of their health journey, making sustainable lifestyle changes that benefit both body and mind. Whether you're looking to lose weight, manage a health condition, or simply improve your eating habits, Fitoja is your go-to app for achieving your health goals.
            </p>
            <p>
                <strong>Download Fitoja today and start your journey to a healthier, happier you!</strong>
            </p>
        </div>
    );
};

export default AboutUs;
