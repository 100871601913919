import React from "react";
import "./TermsAndConditions.scss";

const TermsAndConditions = () => {
    return (
        <div className="terms-and-conditions">
            <h1>Terms and Conditions for Fitoja</h1>
            <p>
                Welcome to Fitoja! These Terms and Conditions ("Terms") govern your use of the Fitoja mobile application ("App") available on the Google Play Store. By downloading or using the App, you agree to comply with and be bound by these Terms. If you do not agree to these Terms, please do not use the App.
            </p>

            <h2>1. Acceptance of Terms</h2>
            <p>
                By accessing or using the Fitoja App, you affirm that you are at least 18 years old or have the consent of a parent or guardian. If you are using the App on behalf of an organization, you represent that you have the authority to bind that organization to these Terms.
            </p>

            <h2>2. Description of the App</h2>
            <p>
                Fitoja provides personalized diet plans based on user-specific information such as gender, age, health condition, weight, and lifestyle choices. The App also includes health tracking features, an in-house store for purchasing Ayurvedic herbs and spices, and access to health and nutrition blogs.
            </p>

            <h2>3. User Accounts</h2>
            <p>To access certain features of the App, you may need to create an account. You agree to:</p>
            <ul>
                <li>Provide accurate, current, and complete information during the registration process.</li>
                <li>Maintain the security of your password and account.</li>
                <li>Notify us immediately of any unauthorized use of your account.</li>
                <li>Take responsibility for all activities that occur under your account.</li>
            </ul>

            <h2>4. User Obligations</h2>
            <p>You agree not to:</p>
            <ul>
                <li>Use the App for any unlawful purpose or in a way that violates any applicable laws.</li>
                <li>Impersonate any person or entity or misrepresent your affiliation with any person or entity.</li>
                <li>Engage in any conduct that restricts or inhibits anyone's use or enjoyment of the App.</li>
            </ul>

            <h2>5. Intellectual Property</h2>
            <p>
                All content, features, and functionality in the App, including but not limited to text, graphics, logos, and software, are the exclusive property of Fitoja or its licensors. You are granted a limited, non-exclusive, non-transferable license to use the App for personal, non-commercial purposes.
            </p>

            <h2>6. Purchases</h2>
            <p>If you make purchases through the in-house store, you agree to:</p>
            <ul>
                <li>Provide accurate payment information.</li>
                <li>Be responsible for all charges incurred through your account.</li>
                <li>Review and understand the terms and conditions of any third-party payment processors.</li>
            </ul>

            <h2>7. Disclaimers</h2>
            <p>
                The information provided by Fitoja is for informational purposes only and should not be considered medical advice. Consult a healthcare professional before making any significant changes to your diet or exercise routine.
            </p>

            <h2>8. Limitation of Liability</h2>
            <p>
                To the fullest extent permitted by law, Fitoja shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising from your use of or inability to use the App.
            </p>

            <h2>9. Termination</h2>
            <p>
                We reserve the right to suspend or terminate your access to the App at our discretion, without notice, for conduct that we believe violates these Terms or is harmful to other users or us.
            </p>

            <h2>10. Changes to Terms</h2>
            <p>
                We may update these Terms from time to time. We will notify you of any changes by posting the new Terms in the App and updating the effective date. Your continued use of the App after any changes constitutes your acceptance of the new Terms.
            </p>

            <h2>11. Governing Law</h2>
            <p>
                These Terms shall be governed by and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law principles.
            </p>

            <h2>12. Contact Us</h2>
            <p>If you have any questions about these Terms, please contact us at:</p>
            <p>
                Email: <a href="mailto:info@fitoja.in">info@fitoja.in</a>
            </p>

            <p>Thank you for using Fitoja! We wish you success on your health and wellness journey.</p>
        </div>
    );
};

export default TermsAndConditions;
